// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.container
  input-width-classes()
  // Overwriting react-select styling in global scope.

  // @stylint off
  :global(.select__option)
    padding: $cs.xs $cs.s
  :global(.select__option--is-selected)
    background-color: $c-active-blue
  :global(.select__option--is-focused:not(.select__option--is-selected))
    background-color: $c-backdrop
  :global(.select__control--is-focused)
    box-shadow: none
    border-color: $c-active-blue
    color: black

  :global(.select__control)
    width: 100%
    min-height: $default-input-height
    border-radius: $br.s
  :global(.select__menu)
    z-index: $zi.dropdown
  :global(.select__dropdown-indicator)
    padding: 4px
  :global(.select__value-container)
    padding: 0 8px
    white-space: nowrap
  :global(.select__clear-indicator)
    cursor: pointer

    div:last-child
      padding:0 !important

  &.error
    :global(.select__control)
      color: $c-error
      border-color: $c-error
      background-color: lighten($c-error, 49)

    :global(.select__control--is-focused)
      box-shadow: 0 0 0 3px alpha($c-error, .2)

  &.warning
    :global(.select__control)
      color: $c-warning
      border-color: $c-warning
      background-color: lighten($c-warning, 42)

    :global(.select__control--is-focused)
      box-shadow: 0 0 0 3px alpha($c-warning, .3)

  &.selected-options-container
    display: inline-block

    .remove-option-button
      float: right
  // @stylint on
