// Copyright © 2023 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.component-cards
  display: flex
  flex-wrap: wrap
  margin-top: $cs.m

.component-section
  margin-top: $ls.l
  margin-bottom: $ls.l

  +media-query($bp.m)
    margin-bottom: $ls.xxs

  hr
    width: 100%

.component-card
  display: inline-flex
  margin: 0 $cs.xl $cs.xl 0
  flex-shrink: 0

  &-icon
    nudge('down')

  &-desc
    one-liner()
    margin-left: $cs.m
    display: flex
    flex-direction: column

    & > span
      display: block

  &-name
    display: flex
    font-weight: 600
    margin-bottom: $cs.xs

    & > span:first-child
      margin-right: $cs.xs

  &-host
    font-family: $font-family-mono
    font-size: $fs.s

.component-info-section
  display: flex
  flex-direction: column

.version-info-section
  display: flex
  flex-direction: column

.version-value
  font-size: $fs.xl3
  font-weight: $fwh.bolder

.version-revision
  margin-top: 0
  font-size: $fs.s
  margin-bottom: $cs.s
