// Copyright © 2022 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.container
  display: flex
  border: 1px solid $c-divider-dark

  +media-query-min($bp.xs)
    flex-direction: row
    max-width: 52.4rem
    padding: $cs.m $cs.l $cs.m $cs.m

  +media-query($bp.xs)
    flex-direction: column
    text-align: center
    padding: $cs.m

.name
  margin: 0
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.image
  object-fit: contain
  align-self: center

  +media-query-min($bp.xs)
    padding-right: $cs.m
    width: 10rem
    max-height: 8.5rem

  +media-query($bp.xs)
    padding-right: 0
    margin: 0 auto
    width: auto
    margin-bottom: $cs.m
    max-height: 10rem

.info
  display: flex
  flex-direction: column
  justify-content: flex-start

  &-item:not(:last-child)
    padding-right: $cs.xxs

    &:after
      content: ','

    &:last-child
      padding: 0

      &:after
        display: none

.description
  text-margin-top($cs.l)

.links
  margin-top: $cs.m
