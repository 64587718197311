// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.table
  border-collapse: collapse
  width: 100%
  table-layout: fixed
  display: table

  .empty-message
    display: table-caption
    caption-side: bottom
    text-align: center
    margin-top: $ls.xs
    color: $tc-subtle-gray
    font-weight: normal

.section

  &-header
    display: table-header-group

  &-body
    display: table-row-group

  &-footer
    display: flex
    justify-content: center
