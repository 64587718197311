// Copyright © 2020 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.container
  display: flex
  justify-content: space-between
  align-items: center
  flex-wrap: wrap
  margin: $ls.s 0 $ls.xxs

.content
  display: flex
  align-items: center
  flex-wrap: wrap
  width: 100%

.creation-date
  one-liner()
  color: $tc-subtle-gray

  +media-query($bp.xxs)
    padding-top: $cs.xs
    flex-basis: 100%

.bottom-bar
  &, & > div
    display: flex

  width: 100%
  justify-content: space-between
