// Copyright © 2020 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.template-info
  display: flex
  justify-content: flex-start
  flex-direction: row
  margin-bottom: $ls.s
  margin-top: $ls.s

.info, .description
  display: block

.info
  color: $tc-subtle-gray

.description-box
  display: flex
  flex-direction: column
  justify-content: center

.logo
  display: flex
  align-items: center
  margin-right: $ls.s

  img
    max-width: 6rem
    max-height: 6rem
    width: 100%
    height: auto
    object-fit: contain
