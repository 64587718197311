// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.icon
  material-icon()

  &.small
    font-size: 1rem

  &.large
    font-size: 2rem

  &.nudgeUp
    position: relative
    top: -1px

  &.nudgeDown
    position: relative
    top: 1px

.text-padded
  &-right
    margin-right: $cs.xxs
  
  &-left
    margin-left: $cs.xxs
