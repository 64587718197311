// Copyright © 2021 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.introduction
  display: flex
  flex-direction: row

  img
    margin-left: $ls.s

.info
  margin-top: 0

.h-rule
  margin-top: $ls.xs
  margin-bottom: $ls.m

.logo
  display: block
  max-width: 12rem

.further-resources
  margin-bottom: $cs.xs

.sub-title
  margin-top: $ls.m

.switch-info
  +media-query-min($bp.s)
    border-dark('left')

  +media-query($bp.s)
    margin-top: $cs.xs
    color: $tc-subtle-gray

.description
  font-weight: $fw.normal
  display: block

.feature-info
  &:not(:first-child)
    margin-top: $ls.xxs

  & > span
    display: flex
    flex-direction: row
    align-items: center

.toggle-container
  display: flex
  flex-direction: row
  align-items: center

  .toggle
    margin-left: $cs.m

  &.disabled
    opacity: .5

.routing-policy-information
  margin: $ls.xs 0

.set-routing-policy-container
  margin-top: $ls.m

.device-address-block-row
  display: flex
  flex-direction: row
  margin-bottom: $cs.m

  & > div
    display: flex
    align-items: center

    &:not(:last-child)
      margin-right: $cs.l

    & > span:first-child
      color: $tc-subtle-gray
      margin-right: $cs.xs

.back-link
  display: block
  margin-top: $cs.xl * -1
  margin-bottom: $cs.l
