// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.container
  display: inline-flex
  align-items: center
  position: relative
  cursor: pointer
  transition: background-color $ad.s
  height: 100%
  padding: 0 $cs.xl

  &:hover
    area-hover()

  +focus-visible()
    background-color: $c-backdrop-lighter

  &:active, &:focus
    area-active()

.id
  one-liner()
  margin-right: $cs.xs

  +media-query($bp.l)
    display: none

.dropdown
  top: $header-height

  &-icon
    margin-right: -.4rem

.profile-picture
  height: 3rem
  width: 3rem
  margin-right: $cs.m
